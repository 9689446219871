

.toasterClass {
    position: fixed;
    top: 17%; /* Adjust the top position as needed */
    left: 35%; /* Adjust the left position as needed */
    background: #53A517 0% 0% no-repeat padding-box !important;
    border-radius: 0px 0px 10px 10px !important;
    opacity: 1 !important;
    width: 550px !important;
    height: 62px !important;
    box-shadow: none !important;
    color: white !important;
    fill: white !important;
  }

.toasterClass.error {
    background: #FF5733 !important; /* Background color for error */
  }

.toasterClass.topLeft {
  top: 10%;
  left: 1%;
}

.toasterText {
    font-size: 15px;
    line-height: 22px;
    font-family: 'Open Sans';
    font-weight: 400; 
    /* color: #FFFFFF; */
}