.trend-box {
    width: 200px; /* Adjust the width as needed */
    height: 60px; /* Adjust the height as needed */
    border: 1px solid #ccc;
    padding: 20px;
    margin: 10px;
    cursor: pointer;
    text-align: center;
}

.trend-dashboard {
    position: relative;
  }
  
  .trend-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Adjust as needed */
    position: relative; /* Ensure position is relative */
  }
  
  .col-lg-6 {
    position: relative;
    overflow: hidden;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.common-box {
    display: flex;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    background-color: #961a1a !important; 
}

/* Left Side Styles */
.tab-container-left {
    flex: 1;
    overflow: hidden;
    transition: transform 0.3s ease;
}

.tab-heading {
    padding: 20px;
    text-align: left;
    font-size: 12px;
}

.tab-heading h5 {
    margin-bottom: 10px;
    font-size: 1.5rem;
    color: #333;
    transition: color 0.3s ease;
}

.tab-heading p {
    color: #000000;
    transition: color 0.3s ease;
    font-size: 14px;
    font-family: sans-serif;
    /* text-align: justify; */
}

/* Vertical Line Styles */
.vertical-line {
    width: 2px;
    height: 100%;
    background-color: #c41515; /* Adjust line color as needed */
    margin: 0 10px; /* Adjust the margin as needed */
}

/* Right Side Styles */
.tab-container-right {
    flex: 1;
    overflow: hidden;
    transition: transform 0.3s ease;
}

.tab-image-violation {
  background-image: url("/src/assets/images/violations.jpg");
  background-size: cover; /* Ensure the background image covers the entire container */
  background-position: center; /* Center the background image */
  border-radius: 10px;
  /* overflow: hidden; */
  width: 100%;
  height: 290px;
}

.tab-image img {
    width: 100%;
    height: auto;
    display: block;
    transition: transform 0.3s ease;
}

/* Hover Effects */
.col-lg-6:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    transform: scale(1.05);
}

.col-lg-6:hover .tab-heading h5,
.col-lg-6:hover .tab-heading p {
    color: #ff6600; /* Change to your desired hover color */
}

.col-lg-6:hover .tab-image img {
    transform: scale(1.05);
}

.left-text-head{
  background-color:ghostwhite;
  border-radius: 5px;
}

.btn-round {
  border-radius: 50%;
  height: 40px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  background: #FF7344 0% 0% no-repeat padding-box;
  box-shadow: 0px 7px 64px #00000012;
  font-size: 14px;
  line-height: 22px;
  font-family: 'Open Sans';
  font-weight: 600;
  letter-spacing: 0px;
  color: #FFFFFF;
  outline: none;
  border: none;
  padding: 5px 20px;
}

.btn-round:hover {
  background-color: #d84c0c; 
  transform: scale(1.1);
  outline: none;
  border: none;
}

/* Rounded List Styles */
.rounded-list {
  list-style-type: none;
  padding: 0;
  border-radius: 10px;
  background-color: #e9e2e2; /* Adjust list background color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: Add box shadow for a subtle effect */
  margin-top : 10px;
  overflow-y: auto;
  max-height: 85px;
}

.rounded-list li {
  padding: 10px;
  border-bottom: 1px solid #ccc; /* Optional: Add border between list items */
  cursor: pointer;
}

.tab-image-trend {
  background-image: url("/src/assets/images/trends.jpg");
  background-size: cover; 
  background-position: center;
  border-radius: 10px;
  /* overflow: hidden; */
  width: 100%;
  height: 290px;
}
.tab-image-excursion {
  background-image: url("/src/assets/images/excursion.jpg");
  background-size: cover; /* Ensure the background image covers the entire container */
  background-position: center; /* Center the background image */
  border-radius: 10px;
  /* overflow: hidden; */
  width: 100%;
  height: 290px;
}
.tab-image-summary {
  background-image: url("/src/assets/images/summary.jpg");
  background-size: cover; /* Ensure the background image covers the entire container */
  background-position: center; /* Center the background image */
  border-radius: 10px;
  /* overflow: hidden; */
  width: 100%;
  height: 290px;
}

.selectedArea{
  cursor: not-allowed;
}

.row {
  display: flex;
}

.item {
  width: calc(24% - 14px); /* Adjust the width as needed */
  margin: 10px;
  padding: 20px;
  border: 1px solid #ccc;
  display: inline-flex;
  transition: background-color 0.3s ease;
  background-color: #4de6f1;
  font-size: 18px;
  cursor: pointer;
  justify-content: center;
  border-radius: 5px;
  font-weight: bold;
}

.navigation-violation{
  text-align: center;
  margin-top : 5%;
}


.item:hover {
  background-color: #ee7676;
  transform: scale(1.1);
}


.calendar-container {
  flex: 1;
  padding: 20px;
}


.time-slots-container {
  flex: 2; 
  overflow-y: auto;
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  max-height: 400px;
  margin-right: 50px;
}

.time-slot {
   position: relative;
   padding-bottom: 17px;
}

.operator-schedule {
  display: flex;
  align-items: flex-start; 
  margin-top : 20px;
}

.current-hour {
  background-color: #ffffff;
}

.time-slot::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 50%; 
  height: 1px;
  background-color: #d1c7c7; 
}

.current-hour{
  background-color: rgb(221, 195, 195); 
}

.time{
  font-weight: bold; 
}

.status-containers {
  display: flex;
  margin-top: 3%;
  text-align: center;
}

.status-column {
  flex: 1;
  padding: 0 10px;
}

.status-column:not(:last-child) {
  border-right: 1px solid #ccc; /* Vertical line separating columns */
}



/* Calendar container */
.react-calendar {
  width: 350px;
  max-width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-left: 20px;
}

/* Header */
.react-calendar__navigation {
  background-color: #36405D;
  border-bottom: 1px solid #ccc;
  color: #e0e0e0;
}

/* Previous and Next buttons */
.react-calendar__navigation button {
  font-size: 14px;
  color: #fdfafa;
}

/* Month and Year label */
.react-calendar__navigation button.react-calendar__navigation__label {
  font-size: 16px;
  color: #ffffff;
}

/* Weekday labels */
.react-calendar__month-view__weekdays {
  background-color: #f0f0f0;
  color: #333;
  text-decoration: none;
}

/* Day tiles */
.react-calendar__month-view__days__day {
  font-size: 14px;
  color: #333;
}

/* Hover effect on day tiles */
.react-calendar__month-view__days__day--hover {
  background-color: #e0e0e0;
}

/* Active (selected) day tile */
.react-calendar__tile--active {
  background-color: #007bff;
  color: #fff;
}

/* Today's day tile */
.react-calendar__tile--now {
  background-color: #ff6347;
  color: #fff;
}

.react-calendar__navigation__label--label {
  background-color: #007bff; 
  color: #fff; 
  padding: 8px;
  border-radius: 4px; 
}


.tab-image-trend {
  width: 100%;
  height: 100%;
  perspective: 1000px; /* Set perspective to create the 3D effect */
}

.tab-image-trend .tab-image-container {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d; /* Preserve 3D space for children */
  transition: transform 0.5s; /* Add a transition for smooth animation */
}

.tab-image-trend .tab-image-container.flipped {
  transform: rotateY(180deg); /* Rotate the container to flip */
}

.tab-image-trend .tab-image-front,
.tab-image-trend .tab-image-back {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden; /* Hide the backface of the elements */
}

.tab-image-trend .tab-image-back {
  transform: rotateY(180deg); /* Initially hide the back side */
}

.tab-image-trend .tab-image-front {
  background-image: url("/src/assets/images/trends.jpg");
  background-size: cover; 
  background-position: center;
  border-radius: 10px;
  width: 100%;
  height: 290px;
}

.tab-image-trend .tab-image-back {
  width: 98%;
  height: 260px;
  overflow-y: auto;
  transform: rotateY(180deg);
}

.tab-image-trend .tab-image-back ul {
  list-style: none;
  padding: 0;
}

.tab-image-trend .tab-image-back li {
  background-color: #FF7344 ; /* Default background color */
  border: 1px solid;
  padding: 10px;
  margin-bottom: 10px;
  transition: background-color 0.3s, transform 0.3s;
  margin-right: 15px;
  margin-top: 19px;
  cursor: pointer;
  color: rgb(255, 255, 255);
  border-radius: 5px; 
  font-weight: 600;
}

.tab-image-trend .tab-image-back li:hover {
  background-color: #FFA07A; /* Background color on hover */
  transform: scale(1.05); 
  color: #fff;
}

.tab-image-trend .tab-image-back li {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2); 
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s; 
  background: linear-gradient(135deg, #FF7344, #FFA07A);
}

.tab-image-trend .tab-image-back li {
  border: 1px solid transparent;
  border-image: linear-gradient(135deg, #FF7344, #FFA07A) 1;
  border-radius: 5px;
}
.tab-image-trend .tab-image-back li {
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 14px;
}

.analyticFilterDate input {
  width: 150px !important;
  height: 35px;
}

.auditTrialReportDate input {
  width: 210px !important;
  height: 35px;
}

.analyticalFilterApply{
  background: #FF7344  0% 0% no-repeat padding-box;
  box-shadow: 0px 7px 64px #00000012;
  border-radius: 10px;
  font-size: 12px;
  line-height: 22px;
  font-family: 'Open Sans';
  font-weight: 600;
  letter-spacing: 0px;
  color: #FFFFFF;
  outline: none;
  border: 1px solid #FF7344;
  padding: 0px 10px;
  margin-right: 20px;
}

.analyticalFilterClose{
  background: #FFFFFF  0% 0% no-repeat padding-box;
  box-shadow: 0px 7px 64px #00000012;
  border-radius: 10px;
  font-size: 12px;
  line-height: 22px;
  font-family: 'Open Sans';
  font-weight: 600;
  letter-spacing: 0px;
  color: black;
  outline: none;
  border: 1px solid black;
  padding: 0px 10px;
  margin-right: 20px;
}

.analyticalFilterDownload{
  background: #ff1212  0% 0% no-repeat padding-box;
  box-shadow: 0px 7px 64px #00000012;
  border-radius: 10px;
  font-size: 12px;
  line-height: 22px;
  font-family: 'Open Sans';
  font-weight: 600;
  letter-spacing: 0px;
  color: #FFFFFF;
  outline: none;
  border: 1px solid #ff1212;
  padding: 0px 10px;
  margin-right: 20px;
}

.analyticalFilterDisabledDownload{
  background: #ff1212  0% 0% no-repeat padding-box;
  box-shadow: 0px 7px 64px #00000012;
  border-radius: 10px;
  font-size: 12px;
  line-height: 22px;
  font-family: 'Open Sans';
  font-weight: 600;
  letter-spacing: 0px;
  color: #FFFFFF;
  outline: none;
  border: 1px solid #ff1212;
  padding: 0px 10px;
  margin-right: 20px;
  opacity: 0.5;
}

.filterBtn {
  background: #FF7344  0% 0% no-repeat padding-box;
  box-shadow: 0px 7px 64px #00000012;
  border-radius: 10px;
  font-size: 12px;
  line-height: 22px;
  font-family: 'Open Sans';
  font-weight: 600;
  letter-spacing: 0px;
  color: #FFFFFF;
  outline: none;
  border: 1px solid #FF7344;
  padding: 0px 10px;
  margin-right: 30px;
  margin-top: 15px;
}

/* excursion section starts here */
.tab-image-excursion {
  width: 100%;
  height: 100%;
  perspective: 1000px; /* Set perspective to create the 3D effect */
}

.tab-image-excursion .tab-image-container {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d; /* Preserve 3D space for children */
  transition: transform 0.5s; /* Add a transition for smooth animation */
}

.tab-image-excursion .tab-image-container.flipped {
  transform: rotateY(180deg); /* Rotate the container to flip */
}

.tab-image-excursion .tab-image-front,
.tab-image-excursion .tab-image-back {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden; /* Hide the backface of the elements */
}

.tab-image-excursion .tab-image-back {
  transform: rotateY(180deg); /* Initially hide the back side */
}

.tab-image-excursion .tab-image-front {
  background-image: url("/src/assets/images/excursion.jpg");
  background-size: cover; 
  background-position: center;
  border-radius: 10px;
  width: 100%;
  height: 290px;
}

.tab-image-excursion .tab-image-back {
  width: 90%;
  height: 260px;
  overflow-y: auto;
  transform: rotateY(180deg); /* Initially hide the back side */
}

.tab-image-excursion .tab-image-back ul {
  list-style: none;
  padding: 0;
}

.tab-image-excursion .tab-image-back li {
  background-color: #FF7344 ; /* Default background color */
  border: 1px solid;
  padding: 10px;
  margin-bottom: 10px;
  transition: background-color 0.3s, transform 0.3s;
  margin-right: 15px;
  margin-top: 19px;
  cursor: pointer;
  color: rgb(255, 255, 255);
  border-radius: 5px; 
  font-weight: 600;
}

.tab-image-excursion .tab-image-back li:hover {
  background-color: #FFA07A; /* Background color on hover */
  transform: scale(1.05); 
  color: #fff;
}

.tab-image-excursion .tab-image-back li {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2); 
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s; 
  background: linear-gradient(135deg, #FF7344, #FFA07A);
}

.tab-image-excursion .tab-image-back li {
  border: 1px solid transparent;
  border-image: linear-gradient(135deg, #FF7344, #FFA07A) 1;
  border-radius: 5px;
}
.tab-image-excursion .tab-image-back li {
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 13px;
}



/* violation section starts here */
.tab-image-violation {
  width: 100%;
  height: 100%;
  perspective: 1000px; /* Set perspective to create the 3D effect */
}

.tab-image-violation .tab-image-container {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d; /* Preserve 3D space for children */
  transition: transform 0.5s; /* Add a transition for smooth animation */
}

.tab-image-violation .tab-image-container.flipped {
  transform: rotateY(180deg); /* Rotate the container to flip */
}

.tab-image-violation .tab-image-front,
.tab-image-violation .tab-image-back {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden; /* Hide the backface of the elements */
}

.tab-image-violation .tab-image-back {
  transform: rotateY(180deg); /* Initially hide the back side */
}

.tab-image-violation .tab-image-front {
  background-image: url("/src/assets/images/violations.jpg");
  background-size: cover; 
  background-position: center;
  border-radius: 10px;
  width: 100%;
  height: 290px;
}

.tab-image-violation .tab-image-back {
  width: 98%;
  height: 260px;
  overflow-y: auto;
  transform: rotateY(180deg);
}

.tab-image-violation .tab-image-back ul {
  list-style: none;
  padding: 0;
}

.tab-image-violation .tab-image-back li {
  background-color: #FF7344 ; /* Default background color */
  border: 1px solid;
  padding: 10px;
  margin-bottom: 10px;
  transition: background-color 0.3s, transform 0.3s;
  margin-right: 15px;
  margin-top: 19px;
  cursor: pointer;
  color: rgb(255, 255, 255);
  border-radius: 5px; 
  font-weight: 600;
}

.tab-image-violation .tab-image-back li:hover {
  background-color: #FFA07A; /* Background color on hover */
  transform: scale(1.05); 
  color: #fff;
}

.tab-image-violation .tab-image-back li {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2); 
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s; 
  background: linear-gradient(135deg, #FF7344, #FFA07A);
}

.tab-image-violation .tab-image-back li {
  border: 1px solid transparent;
  border-image: linear-gradient(135deg, #FF7344, #FFA07A) 1;
  border-radius: 5px;
}
.tab-image-violation .tab-image-back li {
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 14px;
}


/* Summary section starts here */
.tab-image-summary {
  width: 100%;
  height: 100%;
  perspective: 1000px; /* Set perspective to create the 3D effect */
}

.tab-image-summary .tab-image-container {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d; /* Preserve 3D space for children */
  transition: transform 0.5s; /* Add a transition for smooth animation */
}

.tab-image-summary .tab-image-container.flipped {
  transform: rotateY(180deg); /* Rotate the container to flip */
}

.tab-image-summary .tab-image-front,
.tab-image-summary .tab-image-back {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden; /* Hide the backface of the elements */
}

.tab-image-summary .tab-image-back {
  transform: rotateY(180deg); /* Initially hide the back side */
}

.tab-image-summary .tab-image-front {
  background-image: url("/src/assets/images/summary.jpg");
  background-size: cover; 
  background-position: center;
  border-radius: 10px;
  width: 100%;
  height: 290px;
}

.tab-image-summary .tab-image-back {
  width: 90%;
  height: 260px;
  overflow-y: auto;
  transform: rotateY(180deg); /* Initially hide the back side */
}

.tab-image-summary .tab-image-back ul {
  list-style: none;
  padding: 0;
}

.tab-image-summary .tab-image-back li {
  background-color: #FF7344 ; /* Default background color */
  border: 1px solid;
  padding: 10px;
  margin-bottom: 10px;
  transition: background-color 0.3s, transform 0.3s;
  margin-right: 15px;
  margin-top: 19px;
  cursor: pointer;
  color: rgb(255, 255, 255);
  border-radius: 5px; 
  font-weight: 600;
}

.tab-image-summary .tab-image-back li:hover {
  background-color: #FFA07A; /* Background color on hover */
  transform: scale(1.05); 
  color: #fff;
}

.tab-image-summary .tab-image-back li {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2); 
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s; 
  background: linear-gradient(135deg, #FF7344, #FFA07A);
}

.tab-image-summary .tab-image-back li {
  border: 1px solid transparent;
  border-image: linear-gradient(135deg, #FF7344, #FFA07A) 1;
  border-radius: 5px;
}
.tab-image-summary .tab-image-back li {
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 13px;
}



/* Add custom scrollbar styles for .tab-image-violation */
.tab-image-violation .tab-image-back::-webkit-scrollbar {
  width: 10px;
}

.tab-image-violation .tab-image-back::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

.tab-image-violation .tab-image-back::-webkit-scrollbar-thumb {
  background: #FFA07A; 
  border-radius: 10px;
}

.tab-image-violation .tab-image-back::-webkit-scrollbar-thumb:hover {
  background: #FF7344; 
}

.tab-image-violation .tab-image-back::-webkit-scrollbar-button {
  background: #f1f1f1; 
}

.tab-image-violation .tab-image-back::-webkit-scrollbar-corner {
  background: #f1f1f1; 
}

/* Add custom scrollbar styles for .tab-image-trend */
.tab-image-trend .tab-image-back::-webkit-scrollbar {
  width: 10px;
}

.tab-image-trend .tab-image-back::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

.tab-image-trend .tab-image-back::-webkit-scrollbar-thumb {
  background: #FFA07A; 
  border-radius: 10px;
}

.tab-image-trend .tab-image-back::-webkit-scrollbar-thumb:hover {
  background: #FF7344; 
}

.tab-image-trend .tab-image-back::-webkit-scrollbar-button {
  background: #f1f1f1; 
}

.tab-image-trend .tab-image-back::-webkit-scrollbar-corner {
  background: #f1f1f1; 
}

/* Add custom scrollbar styles for tab-image-excursion */
.tab-image-excursion .tab-image-back::-webkit-scrollbar {
  width: 10px;
}

.tab-image-excursion .tab-image-back::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

.tab-image-excursion .tab-image-back::-webkit-scrollbar-thumb {
  background: #FFA07A; 
  border-radius: 10px;
}

.tab-image-excursion .tab-image-back::-webkit-scrollbar-thumb:hover {
  background: #FF7344; 
}

.tab-image-excursion .tab-image-back::-webkit-scrollbar-button {
  background: #f1f1f1; 
}

.tab-image-excursion .tab-image-back::-webkit-scrollbar-corner {
  background: #f1f1f1; 
}

/* Add custom scrollbar styles for tab-image-summary */
.tab-image-summary .tab-image-back::-webkit-scrollbar {
  width: 10px;
}

.tab-image-summary .tab-image-back::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

.tab-image-summary .tab-image-back::-webkit-scrollbar-thumb {
  background: #FFA07A; 
  border-radius: 10px;
}

.tab-image-summary .tab-image-back::-webkit-scrollbar-thumb:hover {
  background: #FF7344; 
}

.tab-image-summary .tab-image-back::-webkit-scrollbar-button {
  background: #f1f1f1; 
}

.tab-image-summary .tab-image-back::-webkit-scrollbar-corner {
  background: #f1f1f1; 
}


/* Card styling */
.card {
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #f8f9fa; /* Light gray background */
  margin: 35px 25px;
  padding: 0;
  transition: background-color 0.3s ease; /* Smooth transition for color change */
  overflow: hidden; /* Ensure content doesn't overflow when scaled */
  align-items: center;
  /* margin-bottom: 25px; */
}

.card:hover {
  background-color: #FF7344; /* Change background color on hover */
  color: #FFFFFF;
  font-weight: 600;
  font-size: 15px;
}

.card-body {
  padding: 5px;
  text-align: center;
  text-wrap: nowrap;
  font-size: 15px;
  font-family: 'Open Sans';
  font-weight: 600;
  color: #333; /* Dark text color */
  text-align: center;
  /* height: 15vh; */
  display: flex;
  align-items: center;
  cursor: pointer;
}

/* .optionSelect {
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #f8f9fa;
  margin: 25px 150px;
  padding: 0;
  transition: background-color 0.3s ease; 
  overflow: hidden; 
  height: 70px; 
  width: 1000px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px; 
  font-weight: 500;
  text-transform: uppercase; 
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center; 
  border-left: 2px solid #ddd; 
  border-top: 2px solid #ddd;
}

.optionSelect:hover {
  background-color: #FF7344; 
  color: #FFFFFF;
  font-weight: 600;
  font-size: 15px;
} */

.option-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.option-label {
  flex: 1;
  font-weight: bold;
  font-size: 18px;
  color: #07144d;
}

.option-description {
  flex: 2;
  margin-left: 40px;
  overflow-y: auto; 
  max-height: 140px; 
  font-family: sans-serif;
}

.optionSelect {
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #f8f9fa;
  margin: 25px 150px;
  padding: 0;
  transition: background-color 0.3s ease;
  overflow: hidden;
  height: 200px;
  width: 400px;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  text-align: left;
  border-left: 2px solid #ddd;
  border-top: 2px solid #ddd;
  padding: 30px;
  background-color: #ddd ;
}

.optionSelect:hover {
  background-color: #FF7344;
  color: #FFFFFF;
  font-weight: 600;
  font-size: 15px;
}

.optionSelect:nth-child(even) {
  margin-right: 50px !important;
}

/* Adjust scrollbar style */
.option-description::-webkit-scrollbar {
  width: 0px; /* Set the width of the scrollbar */
  margin-left: 5px;
}

/* Adjust scrollbar track style */
.option-description::-webkit-scrollbar-track {
  background: transparent; /* Set the background color of the scrollbar track */
}

/* Adjust scrollbar thumb style */
.option-description::-webkit-scrollbar-thumb {
  background: #888; /* Set the color of the scrollbar thumb */
  border-radius: 10px; /* Set the border radius of the scrollbar thumb */
}

/* Adjust scrollbar thumb hover style */
.option-description::-webkit-scrollbar-thumb:hover {
  background: #555; /* Set the hover color of the scrollbar thumb */
}

.violation-tabs {
  display: flex;
  margin-left : 35px;
  /* justify-content:space-around;  */
}

.violation-tab {
  width: calc(50% - 10px); 
  min-height: 80px; 
  background-color: #6a5445; 
  color: white; 
  margin-right: 5px; 
  border-radius: 10px;
  width : 375px;
}

.violation-tab:hover {
  background-color: #6a5445;
  cursor: pointer;
}

.tab-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 10px; 
}

.tab-name {
  font-weight: bold;
  margin-bottom: 10px;
}

.tab-description {
  flex-grow: 1;
}
.violation-tab.active {
  background-color: #FF7344;
  border: #000000;
  border-radius: 1px solid;
}