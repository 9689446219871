.log-list-container {
    width: 50%;
    margin: 20px auto;
    border: 1px solid #ddd;
    height: 65vh; /* Adjust the height to your preference */
    overflow: hidden;
}

.log-list {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
}

.log-list th, .log-list td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

.log-list th {
    background-color: #f2f2f2;
    text-align: center;
    position: sticky;
    top: 0;
    font-size: 16px;
}
.log-list th:hover {
    background-color: #f9f9fc;
}

.log-list tbody {
    display: block;
    height: calc(65vh - 40px); /* Subtract the height of the header row */
    overflow-y: auto;
    width: 101%;
}

.log-list tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.log-list thead, .log-list tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.download-button {
    padding: 5px 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
}

.download-button:hover {
    background-color: #45a049; 
}

/* Custom scrollbar styles */
.log-list tbody::-webkit-scrollbar {
    width: 12px;
}

.log-list tbody::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.log-list tbody::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
    border: 3px solid #f1f1f1;
}

.log-list tbody::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

.log-list tbody .tr:hover {
    background-color: antiquewhite;
}